import { useEffect } from "react";
import { setTopIndex } from "../App";
import CheckDevice from "./CheckDevice";
import "./Contact.css";
import WindowHeader from "./Desktop/WindowHeader";
import BusinessCard from "./BusinessCard";

const Contact = (props) => {
  useEffect(() => {
    setTopIndex("contact");
  }, []);

  return (
    props.show && (
      <CheckDevice>
        <section
          className="contact"
          id="contact"
          onClick={(e) => setTopIndex(e.currentTarget.id)}
        >
          {/* Header */}
          <WindowHeader name="Contact" />

          {/* Body */}
          <div className="contact_wrapper" id="contact">
            <BusinessCard />
          </div>
          {/* <div>
            <div className="contact_wrapper" id="contact">
              <div className="contact_wrapper-grid">
                <div className="contact_wrapper-map_wrapper">
                  <h2>CURRENT LOCATION</h2>
                  <h3>Melbourne</h3>
                  <div className="map">
                    <iframe
                      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d805196.5090548684!2d144.49269118189275!3d-37.97015412137066!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6ad646b5d2ba4df7%3A0x4045675218ccd90!2sMelbourne%20VIC!5e0!3m2!1sen!2sau!4v1606207837310!5m2!1sen!2sau"
                      style={{
                        border: 0,
                      }}
                      width="100%"
                      height="300"
                      frameBorder="0"
                      allowFullScreen
                      aria-hidden="false"
                      tabIndex="0"
                      title="Dev Area"
                    ></iframe>
                  </div>
                </div>

                <div className="contact_wrapper-text">
                  <h2>CONTACT ME</h2>
                  <hr />

                  <div className="contact_wrapper-list">
                    <div className="contact_wrapper-list-item">
                      <div className="icon">
                        <i className="fa fa-phone" aria-hidden="true"></i>
                      </div>
                      <div className="text">
                        <p id="phones">
                          <a id="phone" href="tel: +61 45 25 75 927">
                            +61 45 25 75 927
                          </a>
                        </p>
                      </div>
                    </div>

                    <div className="contact_wrapper-list-item">
                      <div className="icon">
                        <i className="fa fa-envelope" aria-hidden="true"></i>
                      </div>
                      <div className="text">
                        <a href="mailto: kashish.kohli56@gmail.com">
                          kashish.kohli56@gmail.com
                        </a>
                      </div>
                    </div>

                    {/* <!-- <div className="contact_wrapper-list-item">
                    <div className="icon" >
                      <i className="fa fa-skype" aria-hidden="true"></i>
                    </div>
                    <div className="text">
                      me@example.com
                    </div>

                  </div> --> }
                  </div>
                </div>
              </div>
            </div>
          </div> */}
        </section>
      </CheckDevice>
    )
  );
};

export default Contact;
