import React, { useContext } from "react";
import "./Desktop.css";

import { ImFolderOpen } from "react-icons/im";
import About from "./About";
import ModalContext from "../../store/modal-context";
import Finder from "./Finder";
import Skills from "../Tools/Skills";
import Contact from "../Contact";
import Projects from "../Works/Projects";

import CheckDevice from "../CheckDevice";
import Blogs from "../Blogs/Blogs";
// import BusinessCard from "../BusinessCard";

function Desktop() {
  const ctxModalShow = useContext(ModalContext);

  return (
    <div className="main">
      <div className="main_folder-container">
        {console.log("ontouchstart" in window)}
        <CheckDevice>
          <div
            className="main_folder-container_folder"
            data-component="Finder"
            onDoubleClick={ctxModalShow.onClickHandler}
          >
            <ImFolderOpen size={80} name="folder" color="#58CBFC" />
            <label htmlFor="folder" style={{ color: "aliceblue" }}>
              Finder
            </label>
          </div>
        </CheckDevice>
      </div>
      <Finder show={ctxModalShow.isFinderShow} />
      <About show={ctxModalShow.isAboutShow} />
      <Skills show={ctxModalShow.isSkillsShow} />
      <Projects show={ctxModalShow.isProjectsShow} />
      <Contact show={ctxModalShow.isContactShow} />
      <Blogs show={ctxModalShow.isBlogShow} />
      {/* <BusinessCard /> */}
    </div>
  );
}

export default Desktop;
