import "./App.css";
import Footer from "./components/Footer/Footer";
import Desktop from "./components/Desktop/Desktop";

export const setTopIndex = (topZ) => {
  const AboutDiv = document.getElementById("about");
  const SkillsDiv = document.getElementById("skills");
  const ProjectsDiv = document.getElementById("projects");
  const ContactDiv = document.getElementById("contact");
  const BlogsDiv = document.getElementById("blogs");
  const FinderDiv = document.getElementById("finder");
  const FooterDiv = document.getElementById("footer");

  const divs = [
    AboutDiv,
    SkillsDiv,
    ProjectsDiv,
    ContactDiv,
    BlogsDiv,
    FinderDiv,
    FooterDiv,
  ];

  divs.map((e) => e && (e.style.zIndex = ""));
  const topZDiv = document.getElementById(topZ);
  topZDiv && (topZDiv.style.zIndex = "1");
};

function App() {
  return (
    <>
      <div className="App">
        <Desktop />
        <Footer />
      </div>
    </>
  );
}

export default App;
