import { useState, useEffect } from "react";
import { setTopIndex } from "../../App";
import CheckDevice from "../CheckDevice";
import WindowHeader from "../Desktop/WindowHeader";
import "./Projects.css";
import ProjectCards from "./ProjectCards";

import chatapp from "../../images/chat-app.PNG";
import eventapp from "../../images/eventapp.PNG";
import weatherapp from "../../images/weatherapp.PNG";
import roboapp from "../../images/roboapp.PNG";
import dosomethingapp from "../../images/dosomethingapp.PNG";
import ingredientshunt from "../../images/ingredientshunt.PNG";
import metronomeapp from "../../images/metronomeapp.PNG";
import myreads from "../../images/myreads.PNG";
import twitter from "../../images/twitter.PNG";
import snacktime from "../../images/snacktime.png";
import inprogress from "../../images/inprogress.jpeg";
import webScraper from "../../images/web-scraper.png";
import shadowBuilders from "../../images/shadow-builders.png";
import uniLink from "../../images/uni-link.png";
// import fifa from "../../images/FIFADashboard.png";
import ProjectML from "./ProjectML";

const react_projects = [
  {
    image: shadowBuilders,
    projectName: "Shadow Builders Mobile App",
    description: "React Native|Python|AWS",
    link: "",
    source: "https://github.com/kashishkohli20",
  },
  {
    image: inprogress,
    projectName: "E-Commerce Web store",
    description: "React|Django|React Router|Redux ",
    link: "",
    source: "https://github.com/kashishkohli20",
  },
  {
    image: snacktime,
    projectName: "Snack Time",
    description: "React|React Router|Context",
    link: "",
    source: "https://github.com/kashishkohli20",
  },
  {
    image: twitter,
    projectName: "Twitter Like",
    description: "React|React Router|Redux",
    link: "http://twitterlike.surge.sh",
    source: "https://github.com/kashishkohli20",
  },
  {
    image: webScraper,
    projectName: "Web Scraping",
    description: "Python | BeautifulSoup ",
    link: "",
    source: "https://github.com/kashishkohli20",
  },
  {
    image: uniLink,
    projectName: "UniLink",
    description: "Java | SceneBuilder | JavaFX | XML | SQL ",
    link: "",
    source: "https://github.com/kashishkohli20",
  },
  {
    image: myreads,
    projectName: "Myreads App",
    description: "React|React Router",
    link: "http://bookslibrary.surge.sh",
    source: "https://github.com/kashishkohli20",
  },
  {
    image: dosomethingapp,
    projectName: "What To Do App",
    description: "React|React Router",
    link: "http://decisionmaker.surge.sh",
    source: "https://github.com/kashishkohli20",
  },
  {
    image: weatherapp,
    projectName: "Weather Application",
    description: "React|OpenWeatherMap API",
    link: "http://showmeweather.surge.sh",
    source: "https://github.com/kashishkohli20",
  },
  {
    image: ingredientshunt,
    projectName: "Ingredients Hunt App",
    description: "React|React-Router",
    link: "http://ingredientshunt.surge.sh",
    source: "https://github.com/kashishkohli20",
  },
  {
    image: roboapp,
    projectName: "Robos App",
    description: "React",
    link: "http://hellorobos.surge.sh",
    source: "https://github.com/kashishkohli20",
  },
  {
    image: metronomeapp,
    projectName: "Metronome App",
    description: "React",
    link: "http://metronomeapp.surge.sh",
    source: "https://github.com/kashishkohli20",
  },
  {
    image: chatapp,
    projectName: "Chat Application",
    description: "Node|Express|Socket.IO",
    link: "http://sleepy-tor-16456.herokuapp.com",
    source: "https://github.com/kashishkohli20",
  },
  {
    image: eventapp,
    projectName: "Event App Template",
    description: "React|React Reveal",
    link: "http://eventapp.surge.sh",
    source: "https://github.com/kashishkohli20",
  },
];

const ml_projects = [
  {
    title:
      "NIFTY 50 Stock Market Analysis and Prediction — Time Series Analysis",
    problemStatement:
      "Will this pandemic become the scapegoat of companies that were already reeling under losses? Ifyes, how can investors/stakeholders discern the truth from deliberate attempts to keep them in the dark?",
    description:
      "Investigated the performance of 50 companies under the National Stock Exchange for COVID-19 Pandemic. Applied Neural Networks (LSTM) to assist investors gauge the prevailing uncertainty by predicting future close prices.",
    link: "http://twitterlike.surge.sh",
    source: "https://github.com/kashishkohli20",
  },
  {
    title: "Prediction of Mice classes based on protein expression levels",
    problemStatement:
      "Identify different Mice classes based on protein expression levels.",
    description:
      "The goal of this project was to identify different Mice classes based on protein expression levels using Machine Learning and Exploratory Data Analysis. Data Science life cycle was followed with first collecting and preprocessing the data, analyzing the data using Exploratory Data Analysis, and building Machine Learning models to predict Mice classes. Different packages in python like scikit-learn, seaborn, matplotlib, pandas, numpy are used in this project.",
    link: "http://twitterlike.surge.sh",
    source: "https://github.com/kashishkohli20",
  },
  {
    title: "Prediction of Employee Attrition",
    problemStatement: "Uncover the factors that lead to employee attrition.",
    description:
      "The objective of this project was to train and compare five different classifiers to predict the factors that lead to Employee Attrition. Machine Learning and Exploratory Data Analysis are used to predict the factors that lead to Employee Attrition in a company. Five Different classifiers KNN, Decision Tree, Naive Bayes, Random Forests, and Support Vector Machines along with different Machine Learning techniques like cross-validation and Feature Engineering are used in this project to get optimal results.",
    link: "http://twitterlike.surge.sh",
    source: "https://github.com/kashishkohli20",
  },
  {
    title: "FIFA Player Statistics and Value Comparison",
    problemStatement: "Tell a compelling story using data visualisation.",
    description:
      "The data was used to tell a compelling story. The top 10 footballers (arguably) were selected and their overall ranking and traits were compared using Shiny Dashboard.",
    link: "http://twitterlike.surge.sh",
    source: "https://github.com/kashishkohli20",
  },
  {
    title: "Zomato",
    problemStatement:
      "To build a regression model to predict the cost of restaurant for two people based on the given indicators in the training data.",
    description:
      "Cost for 2 people based on the indicators like location, most liked dishes, online/dine-in, type of restaurants etc. were predicted. Clustering was used first to make clusters and then Decision Tree and XGBoost were used and the best model was selected based on best R squared score.  The project follows the ml pipeline: data validation from the client, data transformation, data insertion in the database, data preprocessing, clustering, hyperparameter tuning, pushing to cloud and prediction.",
    link: "http://twitterlike.surge.sh",
    source: "https://github.com/kashishkohli20",
  },
  {
    title: "Wafer Fault Detection",
    problemStatement:
      "To build a classification methodology to predict the quality of wafer sensors based on the given training data.",
    description:
      "Each wafer consists 590 sensors. Quality of these wafer sensors were predicted. Clustering was done to get optimum number of clusters. The idea behind clustering was to implement different algorithms. Random Forests and XGBoost were used and the best model was selected based on best AUC score.  The project follows the ml pipeline: data validation from the client, data transformation, data insertion in the database, data preprocessing, clustering, hyperparameter tuning, pushing to cloud and prediction.",
    link: "http://twitterlike.surge.sh",
    source: "https://github.com/kashishkohli20",
  },
  {
    title: "Phishing Classifier",
    problemStatement:
      "To build a classification methodology to predict whether a website is a phishing website on the basis of given set of predictors.",
    description:
      "As cyber crime is increasing daily, phishing detection is critical for users to know which website to trust. Each website has 30 features which include the url, ip address, domain registertion length, SSL final state etc. Clustering was done to get optimum number of clusters. The idea behind clustering was to implement different algorithms. SVM and XGBoost were used and the best model was selected based on best AUC score. The project follows the ml pipeline: data validation from the client, data transformation, data insertion in the database, data preprocessing, clustering, hyperparameter tuning, pushing to cloud and prediction.",
    link: "http://twitterlike.surge.sh",
    source: "https://github.com/kashishkohli20",
  },
  {
    title: "Big Mart Sales",
    problemStatement:
      "Build a predictive model and find out the sales of each product at a particular store.",
    description:
      "Prediction of sales of each product at a particular store. The data consists 12 attributes of items and outlet such as item weight, item fat content, item visibility, item type etc. Clustering was done to get optimum number of clusters. The idea behind clustering was to implement different algorithms. Random Forests Regressor and Linear Regression were used and the best model was selected based on best R Squared score.  The project follows the ml pipeline: data validation from the client, data transformation, data insertion in the database, data preprocessing, clustering, hyperparameter tuning, pushing to cloud and prediction.",
    link: "http://twitterlike.surge.sh",
    source: "https://github.com/kashishkohli20",
  },
  {
    title: "Fitbit",
    problemStatement:
      "To build a regression model to predict the calories burnt based on the given indicators in the training data.",
    description:
      "The data consists various activity indicators such as date, steps, tracking distance, very active minutes, fairly active minutes, lightly active minutes, calories etc. Clustering was done to get optimum number of clusters. The idea behind clustering was to implement different algorithms. Decision Tree Regressor and XGBoost Regressor were used and the best model was selected based on best R Squared score.  The project follows the ml pipeline: data validation from the client, data transformation, data insertion in the database, data preprocessing, clustering, hyperparameter tuning, pushing to cloud and prediction.",
    link: "http://twitterlike.surge.sh",
    source: "https://github.com/kashishkohli20",
  },
];

const Projects = (props) => {
  const [currentTab, setCurrentTab] = useState("mldl");

  useEffect(() => {
    setTopIndex("projects");
  }, []);

  const tabHandler = (event) => {
    console.log(`currentTab: ${currentTab}`);
    // setCurrentTab(num)
    const tab = event.currentTarget.dataset.tab;
    console.log(tab);
    setCurrentTab(tab);
    const div = event.currentTarget;
    console.log(div);
    div.style.background = "lawngreen";
  };
  return (
    props.show && (
      <CheckDevice>
        <div
          className="projects"
          id="projects"
          onClick={(e) => setTopIndex(e.currentTarget.id)}
        >
          {/* Header */}
          <WindowHeader name="Projects" />

          {/* Body */}
          <div className="projects_main">
            <div className="tabs">
              <div
                className="tablinks"
                data-tab="mldl"
                onClick={tabHandler}
                style={{
                  background: currentTab === "mldl" ? "lawngreen" : "#e23f7e",
                }}
              >
                Data Science/ Machine Learning
              </div>
              <div
                className="tablinks"
                data-tab="js"
                onClick={tabHandler}
                style={{
                  background: currentTab === "js" ? "lawngreen" : "#e23f7e",
                }}
              >
                Developer
              </div>
            </div>

            {currentTab === "mldl" ? (
              <div className="tabcontent">
                {ml_projects.map((app, i) => (
                  <ProjectML
                    key={i}
                    image={app.image}
                    title={app.title}
                    problemStatement={app.problemStatement}
                    description={app.description}
                    link={app.link}
                  />
                ))}
              </div>
            ) : (
              <div className="tabcontent">
                {react_projects.map((app, i) => (
                  <ProjectCards
                    key={i}
                    image={app.image}
                    projectName={app.projectName}
                    description={app.description}
                    link={app.link}
                  />
                ))}
              </div>
            )}
          </div>
        </div>
      </CheckDevice>
    )
  );
};

export default Projects;
