import React from "react";

const BlogCard = (props) => {
  return (
    <div className="blog-card_wrapper">
      <div className="blog-card_name">
        <h2>{props.heading}</h2>
        <span className="description typing">{props.description}</span>
      </div>
      <div style={{ display: "flex", justifyContent: "center", marginTop: 20 }}>
        <a
          color="primary"
          variant="outlined"
          href={props.link}
          target="_blank"
          rel="noreferrer"
        >
          Go
        </a>
      </div>
    </div>
  );
};

export default BlogCard;
