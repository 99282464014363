import React, { useContext, useEffect } from "react";
import CheckDevice from "../CheckDevice";
import WindowHeader from "./WindowHeader";
import { setTopIndex } from "../../App";
import "./Finder.css";
import { ImFolderOpen } from "react-icons/im";
// import { Link as RouteLink } from "react-router-dom";
import ModalContext from "../../store/modal-context";

function Finder(props) {
  useEffect(() => {
    setTopIndex("finder");
  }, []);
  const ctxModalShow = useContext(ModalContext);
  return (
    props.show && (
      <CheckDevice>
        <div
          className="finder"
          id="finder"
          onClick={(e) => setTopIndex(e.currentTarget.id)}
        >
          <WindowHeader name="Finder" />
          <div className="finder__main">
            {/* About */}
            <div
              className="main__folder-container__folder"
              data-component="About"
              onDoubleClick={ctxModalShow.onClickHandler}
            >
              <ImFolderOpen size={80} name="about" color="#58CBFC" />
              <label htmlFor="about">About Me</label>
            </div>

            {/* Skills */}
            <div
              className="main__folder-container__folder"
              data-component="Skills"
              onDoubleClick={ctxModalShow.onClickHandler}
            >
              <ImFolderOpen size={80} name="skills" color="#58CBFC" />
              <label htmlFor="skills">Skills</label>
            </div>

            {/* Projects */}
            <div
              className="main__folder-container__folder"
              data-component="Projects"
              onDoubleClick={ctxModalShow.onClickHandler}
            >
              <ImFolderOpen size={80} name="project" color="#58CBFC" />
              <label htmlFor="project">Projects</label>
            </div>

            {/* Works */}
            {/* <div
              className="main__folder-container__folder"
              data-component="Experience"
              onDoubleClick={ctxModalShow.onClickHandler}
            >
              <ImFolderOpen size={80} name="work" color="#58CBFC" />
              <label htmlFor="work">Works</label>
            </div> */}

            {/* Contact */}
            <div
              className="main__folder-container__folder"
              data-component="Contact"
              onDoubleClick={ctxModalShow.onClickHandler}
            >
              <ImFolderOpen size={80} name="contact" color="#58CBFC" />
              <label htmlFor="contact">Contact</label>
            </div>

            {/* Resume */}
            {/* <RouteLink
              to="resume"
              target="__blank"
              rel="noreferrer"
              className="finder__main__link"
            >
              <div className="main__folder-container__folder">
                <ImFolderOpen size={80} name="resume" color="#58CBFC" />
                <label htmlFor="resume">Resume</label>
              </div>
            </RouteLink> */}

            {/* Blogs */}
            {/* <RouteLink
              to="blogs"
              target="__blank"
              rel="noreferrer"
              className="finder__main__link"
            > */}
            <div
              className="main__folder-container__folder"
              data-component="Blog"
              onDoubleClick={ctxModalShow.onClickHandler}
            >
              <ImFolderOpen size={80} name="blog" color="#58CBFC" />
              <label htmlFor="blog">Blogs</label>
            </div>
            {/* </RouteLink> */}
          </div>
        </div>
      </CheckDevice>
    )
  );
}

export default Finder;
