import React from "react";

const ProjectCards = (props) => {
  return (
    <div className="project_card_wrapper">
      <div
        className="project_card_thmb"
        style={{
          background: `url(${props.image})`,
        }}
      ></div>
      <div className="project_card_nfo">
        <div className="project_card_name">
          <span>{props.projectName}</span>
          <span className="description">Tools: {props.description}</span>
        </div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <div
            color="primary"
            variant="outlined"
            href={props.link}
            target="_blank"
          >
            View Project
          </div>
          <div color="secondary" variant="outlined" href={props.source}>
            View Source
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProjectCards;
