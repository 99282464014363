import React from "react";
import { Route, Switch } from "react-router-dom";
import App from "./../App";
// import Resume from "../components/Resume/Resume";
import NotFound from "../components/NotFound/NotFound";

const AppRouter = () => {
  return (
    <Switch>
      <Route exact path="/" component={App} />
      {/* <Route path="/resume" component={Resume} /> */}
      <Route component={NotFound} />
    </Switch>
  );
};

export default AppRouter;
