import { useEffect } from "react";
import { setTopIndex } from "../../App";
import BlogCard from "./BlogCard";
import CheckDevice from "../CheckDevice";
import WindowHeader from "../Desktop/WindowHeader";

import "./Blogs.css";
// import ModalContext from "../../store/modal-context";

const blogsArray = [
  {
    heading: "Starting with AWS",
    description:
      "It's been so long that I wanted to start my blogging journey. But every time that I wanted to start to write something I would stop in between. Hopefully, if I finish this blog in time then I would have created my first public blog.",
    link: "https://www.notion.so/How-to-get-started-with-Amazon-Web-Services-35cea06df0f045dfac1f4d38fe85bf2c",
  },
  {
    heading: "Setting Up AWS Account Securely",
    description:
      "In the last section, I talked about so many benefits of going cloud. In this section we will take the first step to enjoy the cloud computing.",
    link: "https://www.notion.so/Setting-Up-AWS-Account-Securely-fc18b644ff39431ca48a633856b7c2c1",
  },
  {
    heading: "Getting Started with Natural Language Processing",
    description:
      "It is widely known that Natural Language Processing (NLP) is a part of Artificial Intelligence, developed for the machine to understand human language.",
    link: "https://www.notion.so/Getting-Started-with-Natural-Language-Processing-dc17845f4aed4306a56096a744d06cf0",
  },
  {
    heading: "SQL Revision",
    description:
      "Though I have been using SQL in many software applications but still I wanted to have a query challenge for myself. I went to HackerRank and started exploring SQL over there. This was my first day having an attempt at the SQL challenges.",
    link: "https://www.notion.so/SQL-Revision-471883db83984cb0bfea5cadd713d7c9",
  },
  {
    heading: "Fitbit EDA",
    description:
      "I have explored Fitbit data using Python, NumPy, Pandas, Seaborn and Pandas Profiling. Pandas Profiling is an amazing tool for exploratory data analysis.",
    link: "https://www.notion.so/Fitbit-EDA-0998e5b654ca42a7b5daaea793df4b39",
  },
  // {
  //   heading: "Starting with Super",
  //   description:
  //     "It's been so long that I wanted to start my blogging journey. But every time that I wanted to start to write something I would stop in between. Hopefully, if I finish this blog in time then I would have created my first public blog.",
  //   link: "https://www.notion.so/How-to-get-started-with-Amazon-Web-Services-35cea06df0f045dfac1f4d38fe85bf2c",
  // },
  // {
  //   heading: "Deployed my first static website using AWS",
  //   description:
  //     "It's been so long that I wanted to start my blogging journey. But every time that I wanted to start to write something I would stop in between. Hopefully, if I finish this blog in time then I would have created my first public blog.",
  //   link: "https://www.notion.so/How-to-get-started-with-Amazon-Web-Services-35cea06df0f045dfac1f4d38fe85bf2c",
  // },
  // {
  //   heading: "Starting with Azure",
  //   description: "some description",
  //   link: "https://www.google.com",
  // },
];

const Blogs = (props) => {
  useEffect(() => {
    setTopIndex("blogs");
  }, []);
  return (
    props.show && (
      <CheckDevice>
        <div
          className="blogs"
          id="blogs"
          onClick={(e) => setTopIndex(e.currentTarget.id)}
        >
          {/* Header */}
          <WindowHeader name="Blog" />

          {/* Body */}
          <div className="blogs_main">
            {blogsArray.map((blog, i) => (
              <BlogCard
                key={i}
                heading={blog.heading}
                description={blog.description}
                link={blog.link}
              />
            ))}
          </div>
        </div>
      </CheckDevice>
    )
  );
};

export default Blogs;
