import React, { useState } from "react";

const ModalContext = React.createContext({
  isFinderShow: false,
  onClickHandler: (event) => {},
  onClickCloseHandler: (event) => {},
});

export const ModelContextProvider = (props) => {
  const [isFinderShow, setIsFinderShow] = useState(false);
  const [isAboutShow, setIsAboutShow] = useState(false);
  const [isSkillsShow, setIsSkillsShow] = useState(false);
  const [isProjectsShow, setIsProjectsShow] = useState(false);
  // const [isExperienceShow, setIsExperienceShow] = useState(false);
  const [isContactShow, setIsContactShow] = useState(false);
  const [isBlogShow, setIsBlogShow] = useState(false);

  const onClickHandler = (event) => {
    const component = event.currentTarget.dataset.component;

    if (component === "Finder") setIsFinderShow(true);
    else if (component === "About") setIsAboutShow(true);
    else if (component === "Skills") setIsSkillsShow(true);
    else if (component === "Projects") setIsProjectsShow(true);
    // else if (component === "Experience") setIsExperienceShow(true);
    else if (component === "Contact") setIsContactShow(true);
    else if (component === "Blog") setIsBlogShow(true);
    else if (component === "Home") {
      setIsFinderShow(false);
      setIsAboutShow(false);
      setIsSkillsShow(false);
      setIsProjectsShow(false);
      setIsContactShow(false);
      setIsBlogShow(false);
    }
  };

  const onClickCloseHandler = (event) => {
    const component = event.currentTarget.dataset.component;
    if (component === "Finder") setIsFinderShow(false);
    else if (component === "About") setIsAboutShow(false);
    else if (component === "Skills") setIsSkillsShow(false);
    else if (component === "Projects") setIsProjectsShow(false);
    // else if (component === "Experience") setIsExperienceShow(false);
    else if (component === "Contact") setIsContactShow(false);
    else if (component === "Blog") setIsBlogShow(false);
  };

  return (
    <ModalContext.Provider
      value={{
        isFinderShow: isFinderShow,
        isAboutShow: isAboutShow,
        isSkillsShow: isSkillsShow,
        isContactShow: isContactShow,
        isProjectsShow: isProjectsShow,
        isBlogShow: isBlogShow,
        // isExperienceShow: isExperienceShow,
        onClickHandler: onClickHandler,
        onClickCloseHandler: onClickCloseHandler,
      }}
    >
      {props.children}
    </ModalContext.Provider>
  );
};

export default ModalContext;
