import React, { useContext } from "react";
import { IoCloseCircle } from "react-icons/io5";
import ModalContext from "../../store/modal-context";
import "./WindowHeader.css";

function WindowHeader(props) {
  const ctx = useContext(ModalContext);
  return (
    <div className="window-header">
      <div className="window-header_title">
        <div className="window-header_title_content">
          <div
            className="close"
            data-component={props.name}
            onClick={ctx.onClickCloseHandler}
          >
            <IoCloseCircle size={30} color="tomato" />
          </div>
          <div>{props.name}</div>
        </div>
      </div>
      <div className="window-header_menu">
        <div>File</div>
        <div>Edit</div>
        <div>View</div>
        <div>Go</div>
        <div>Window</div>
        <div>Help</div>
      </div>
    </div>
  );
}

export default WindowHeader;
