import React from "react";
import Draggable from "react-draggable";

function CheckDevice(props) {
  return (
    <Draggable disabled={"ontouchstart" in window}>{props.children}</Draggable>
  );
}

export default CheckDevice;
