import "./BusinessCard.css";

function BusinessCard() {
  return (
    <div className="container">
      <div className="card">
        <div className="front side">
          <h1 className="logo">Kashish Kohli</h1>
        </div>

        <div className="back side">
          <h3 className="name">Kashish Kohli</h3>
          <div>Software developer</div>
          <div className="info">
            <p>
              <span className="property">Email: </span>
              <a href="mailto: kashish.kohli56@gmail.com">
                kashish.kohli56@gmail.com
              </a>
            </p>
            <p>
              <span className="property">Twitter: </span>@_KohliKashish
            </p>
            <p>
              <span className="property">Phone: </span>
              <a href="tel: +61452575927">(+61) 45 25 75 927</a>
            </p>
            <p>
              <span className="property">Website: </span>
              <a
                href="https://kashishkohli.me"
                target="_blank"
                rel="noreferrer"
              >
                kashishkohli.me
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BusinessCard;
