import React, { useContext, useEffect } from "react";
import "./Footer.css";
import { setTopIndex } from "../../App";
import { MdHome } from "react-icons/md";
import { SiAboutDotMe } from "react-icons/si";
import { SiBloglovin } from "react-icons/si";
import { AiOutlineFundProjectionScreen } from "react-icons/ai";
// import { MdWork } from "react-icons/md";
// import { ImProfile } from "react-icons/im";
import { GiGiftOfKnowledge } from "react-icons/gi";
import { GrContact } from "react-icons/gr";

// import { Link as RouteLink } from "react-router-dom";
import ModalContext from "../../store/modal-context";
import CheckDevice from "../CheckDevice";

function Footer() {
  useEffect(() => {
    setTopIndex("footer");
  }, []);
  const ctxModalShow = useContext(ModalContext);
  return (
    <CheckDevice>
      <footer id="footer" onClick={(e) => setTopIndex(e.currentTarget.id)}>
        <div data-component="Home" onClick={ctxModalShow.onClickHandler}>
          <MdHome size={50} name="home" />
          <label htmlFor="home">Home</label>
        </div>
        <div data-component="About" onClick={ctxModalShow.onClickHandler}>
          <SiAboutDotMe size={50} name="about" />
          <label htmlFor="home">About Me</label>
        </div>
        <div data-component="Skills" onClick={ctxModalShow.onClickHandler}>
          <GiGiftOfKnowledge size={50} name="skills" />
          <label htmlFor="skills">Skills</label>
        </div>
        <div data-component="Projects" onClick={ctxModalShow.onClickHandler}>
          <AiOutlineFundProjectionScreen size={50} name="project" />
          <label htmlFor="project">Projects</label>
        </div>
        {/* <div>
          <MdWork size={50} name="work" />
          <label htmlFor="work">Works</label>
        </div> */}
        <div data-component="Contact" onClick={ctxModalShow.onClickHandler}>
          <GrContact size={50} name="contact" />
          <label htmlFor="contact">Contact</label>
        </div>
        {/* <RouteLink
          to="resume"
          target="__blank"
          rel="noreferrer"
          className="finder__main__link"
        >
          <div>
            <ImProfile size={50} name="resume" />
            <label htmlFor="resume">Resume</label>
          </div>
        </RouteLink> */}
        {/* <RouteLink
          to="blogs"
          target="__blank"
          rel="noreferrer"
          className="finder__main__link"
        > */}
        <div data-component="Blog" onClick={ctxModalShow.onClickHandler}>
          <SiBloglovin size={50} name="blog" />
          <label htmlFor="blog">Blogs</label>
        </div>
        {/* </RouteLink> */}
      </footer>
    </CheckDevice>
  );
}

export default Footer;
