import React, { useEffect } from "react";
import { setTopIndex } from "../../App";
import CheckDevice from "../CheckDevice";
import "./About.css";
import profilePic from "../../images/kashish_IG.jpg";
import WindowHeader from "./WindowHeader";

function About(props) {
  useEffect(() => {
    setTopIndex("about");
  }, []);

  return (
    props.show && (
      <CheckDevice>
        <div
          className="about"
          id="about"
          onClick={(e) => setTopIndex(e.currentTarget.id)}
        >
          {/* Header */}
          <WindowHeader name="About" />

          {/* Body */}
          <div className="about_main">
            <div className="about_main_image-wrapper spacing">
              <img
                className="profile-pic"
                src={profilePic}
                alt="Kashish Kohli Profile Pic"
              />
            </div>
            <div className="about_main_text-wrapper spacing">
              <h2>About Me</h2>

              {/* <p>{bio}</p> */}
              <p>Full Stack Developer && Machine Learning Engineer</p>
              <p>
                Software Developer turned Machine Learning Engineer. I realized
                that I am more inclined towards programming skills, statistical
                knowledge, and machine learning techniques so by extrapolating
                and sharing the insights, I can contribute to solving vexing
                problems. Apart from (reverse) engineering, I love to read
                books, follow cricket, play ukulele, watch movies and anime.
              </p>

              <div className="contact-details">
                <h2>Contact Details</h2>
                <p className="address">
                  <span>
                    Want to collaborate with me? That's great! Lets connect on
                    LinkedIn or send me an email and I will get back to you as
                    soon as possible!
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </CheckDevice>
    )
  );
}

export default About;
