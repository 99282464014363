import React, { useEffect } from "react";
import CheckDevice from "../CheckDevice";
import "./Skills.css";
import WindowHeader from "../Desktop/WindowHeader";
import { setTopIndex } from "../../App";

const skills = [
  "Machine Learning",
  "Natural Language Processing",
  "Python",
  "ReactJS",
  "Javascript",
  "Django",
  "NodeJS",
  "Express",
  "Flask",
  "REST API",
  "SQL",
  "git",
  "Web Development",
  "Mobile Application Development",
  "Cloud Services (AWS)",
  "Docker",
  "Tableau",
  "PowerBI",
];

function Skills(props) {
  useEffect(() => {
    setTopIndex("skills");
  }, []);
  return (
    props.show && (
      <CheckDevice>
        <div
          className="skills"
          id="skills"
          onClick={(e) => setTopIndex(e.currentTarget.id)}
        >
          {/* Header */}
          <WindowHeader name="Skills" />

          {/* Body */}
          <div className="skills_main">
            <div className="skills_main_text-wrapper">
              <p>kashishkohli@Kashishs-MBP ~ % cd skills</p>
              <p>kashishkohli@Kashishs-MBP Skills % ls</p>
              <div className="skills_main_text-wrapper_list">
                {skills.map((skill) => (
                  <div key={skill}>{skill}</div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </CheckDevice>
    )
  );
}

export default Skills;
