import "./ProjectML.css";

function ProjectML(props) {
  return (
    <>
      <div className="project-ml_main">
        {/* <div className="project-ml_main_image-wrapper">
          {/* <img className="project-pic" src={props.image} alt="Project pic" /> */}
        {/*</div> */}
        <div className="project-ml_main_text-wrapper">
          <h3>{props.title}</h3>
          <p>
            <strong>Problem Statement:</strong> {props.problemStatement}
          </p>

          <p>{props.description}</p>
        </div>
      </div>
    </>
  );
}

export default ProjectML;
