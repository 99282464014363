import notfound1 from "../../images/notfound-1.jpg";
import notfound3 from "../../images/notfound-3.jpg";

const NotFound = () => {
  return (
    <div
      style={{
        background: `url(${notfound1}) no-repeat`,
        backgroundSize: "cover",
        height: "100vh",
      }}
    >
      <div
        className="image-wrapper"
        style={{ paddingTop: "16%", textAlign: "center" }}
      >
        <img src={notfound3} alt="Not Found" />
      </div>
    </div>
  );
};

export default NotFound;
